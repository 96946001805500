// links
a {
  cursor: pointer;
  color: var(--link-color);
  background-color: initial;
  text-decoration: underline;
  display: inline-block;
  line-height: 1;

  &:hover {
    color: var(--link-color-hover);
  }

  &[data-disabled] {
    color: var(--disabled-color);
  }
}

a:where(:link, :visited, :hover, :active) {
  text-decoration: none;
  color: initial;
}

a.link-navbar {
  text-decoration: none;
  transition: all 0.5s var(--timing-function);
}

a.link-cta {
  color: var(--primary);
  text-decoration: underline;
}

[data-svg-inline='true'],
.svg-inline {
  display: inline-block;
  height: 1em;
}
