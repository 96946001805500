.container {
  position: relative;
  isolation: isolate;

  input {
    display: inline-block;
    width: 100%;
    /* clears the ‘X’ from Internet Explorer */
    &::-ms-clear {
      display: none;
      width: 0;
      height: 0;
    }
    &::-ms-reveal {
      display: none;
      width: 0;
      height: 0;
    }
    /* clears the ‘X’ from Chrome */
    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration,
    &::-webkit-calendar-picker-indicator {
      display: none !important;
    }
  }

  button {
    display: none;
    &[data-visible='true'] {
      display: inline-block;
      width: auto;
    }
    position: absolute;
    appearance: unset;
    background: transparent;
    border: unset;
    padding: 0px;
    margin-left: -26px;
    line-height: 1;
    transform: translateY(-50%);
    top: 50%;
  }
}
