#asu-footer {
  .endorsed-row {
    background: #1e1e1e;
    .social-media {
      .nav-link {
        font-size: 2rem;
        color: #bfbfbf;
      }
    }
  }

  .innovation-row {
    background-color: #ffc627;
    .innovation-container {
      .img-link {
        transform: translateX(2rem);
        img {
          height: 100px;
        }
      }
    }
  }

  .colophon-row {
    background-color: #e8e8e8;
  }

  @media (max-width: 991.98px) {
    .innovation-container {
      flex-direction: column;
      padding: 0.5rem 0 0.5rem 0;

      .img-link {
        transform: none;
        img {
          width: 100%;
          height: auto;
        }
      }
    }

    .innovation-row,
    .colophon-row {
      .nav {
        display: grid;
        grid-template-rows: repeat(3, 1fr);
        grid-auto-flow: column;
        gap: 0;
        width: 100%;
      }

      .nav-link {
        padding: 0.5rem 0;
      }

      .nav-link:nth-child(n + 4) {
        justify-self: end;
      }
    }
  }
}
