.container {
  box-shadow: 0px -3px 6px #00000029;
}

.role-dropdown {
  width: 13rem;
  :global {
    .react-select-indicators-container *,
    .react-select-single-value,
    .react-select-place-holder,
    .react-select-input {
      color: #fff;
    }

    .react-select-indicators-container .react-select-indicator-separator {
      width: 0;
    }

    .react-select-control {
      background-color: var(--gray-700);
      border-radius: 25px;
      padding-inline: 1rem;
      font-weight: bold;
      border-color: #747474;
    }
  }
}
