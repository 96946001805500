:global {
  /* ISSUE: DIV's beneath "Dialog" element display their scrollbar
            when "Dialog"element is open.
     FIX: Hide any scrollbar when "Dialog" element is open. */
  //  TODO: This breaks the new UI. Consider to remove it after testing every modals
  //  TODO:body:has(dialog[open]) * {
  //  TODO:  ::-webkit-scrollbar {
  //  TODO:    background-color: transparent !important;
  //  TODO:  }
  //  TODO:  ::-moz-scrollbar {
  //  TODO:    background-color: transparent !important;
  //  TODO:  }
  //  TODO:}

  /* stop body scrolling when a Dialog is open */
  html:has(dialog[open]),
  body:has(dialog[open]) {
    overflow-y: hidden;
  }

  /* Open/close transitions */
  @keyframes modal-fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes modal-fade-out {
    0% {
      opacity: 1;
    }
    100% {
      display: none;
      opacity: 0;
    }
  }

  @keyframes modal-slide-up {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0);
    }
  }

  @keyframes modal-slide-down {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(100%);
    }
  }
}

.container {
  box-sizing: content-box;
  padding: 0;

  &:focus {
    box-shadow: unset !important;
  }

  &[open] {
    animation:
      modal-slide-up 500ms forwards,
      modal-fade-in 500ms forwards;
  }

  &[closing] {
    pointer-events: none;
    inset: 0;
    animation:
      modal-fade-out 0.5s forwards,
      modal-slide-down 500ms forwards;
  }

  &::backdrop {
    background-color: var(--backdrop-color);
    opacity: 0;
  }

  &[open]::backdrop {
    animation: modal-fade-in 500ms forwards;
  }

  &[closing]::backdrop {
    animation: modal-fade-out 500ms forwards;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  padding-inline: 1.5rem;
  padding-block: 2rem;

  &[data-header-style='dark'] {
    background: var(--gray-700);
    color: #ffffff;
  }

  &[data-header-no-padding='true'] {
    padding: 0;
  }

  &[data-show-header-separator='true'] {
    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
  }

  &[data-is-empty='true'] {
    display: none;
  }

  &[data-show-sticky-header='true'] {
    position: absolute;
    isolation: isolate;
    z-index: 1;
    right: 0;
  }

  .header-title {
    font-size: 2rem;
    font-weight: bold;
    line-height: 2rem;
    letter-spacing: -1.02px;
    padding-right: 1rem;
  }

  .btn-close {
    color: inherit;
    position: absolute;
    z-index: 1;
    top: 1rem;
    right: 1rem;
    outline: none;
    box-shadow: none;
  }
}

.body {
  [data-has-header='false'] & {
    height: 100%;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  &[data-body-no-padding='true'] {
    padding: 0;
  }

  &[data-body-full-height='true'] {
    height: 100%;
    min-height: inherit;
  }
}
