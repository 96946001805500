.container {
  border-radius: inherit;
  width: var(--size, inherit);
  height: var(--size, inherit);

  &[data-disabled='true'] {
    cursor: not-allowed;
    color: inherit;
  }
}
