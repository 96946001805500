.container {
  --spinner-color: var(--gray-800);
  --bg-red: 255;
  --bg-green: 255;
  --bg-blue: 255;
  --bg-opacity: 0.7;

  font-size: 1.25rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &[data-full-size='true'],
  &[data-attach-container='element'] {
    background-color: rgba(
      var(--bg-red),
      var(--bg-green),
      var(--bg-blue),
      var(--bg-opacity)
    );

    color: #000;

    width: 100%;
    height: 100%;
    max-height: 100vh;

    position: absolute;
    isolation: auto;
    z-index: 10;
    left: 0;
    top: 0;
  }

  &[data-full-opacity='true'] {
    --bg-opacity: 1;
  }

  &[data-full-size='true'] {
    width: 100vw;
  }

  :global {
    #text-spinner {
      color: var(--spinner-color);
      text-align: center;
    }
  }
}

.content {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.25rem;

  &[data-frame-inner-content='true'] {
    border-radius: 1rem;
    border: 2px solid var(--gray-500);
    backdrop-filter: blur(5px);
    box-shadow: rgb(208, 208, 208) 0px 0pc 20px 4px;
    background-color: var(--white);
  }
}

@mixin animation {
  & {
    transition-property: transform;
    animation: rotate;
    animation-duration: 1.2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}

.circle-container {
  circle {
    fill: var(--spinner-color);
  }
  @include animation();
}

.wheel-container {
  border: 4px solid #fcd779;
  border-top: 4px solid white;
  border-radius: 50%;

  @include animation();
}
