.container {
  &:where([data-input-type='radio'], [data-input-type='checkbox']) {
    &[data-input-type='radio'] {
      label::before,
      label::after {
        border-radius: 50%;
      }
    }

    input:focus + label:after,
    input:focus + label:before {
      border: 2px solid #191919;
    }

    label {
      &::before {
        content: '';
        position: absolute;
        display: inline-block;
        left: -2.25rem;
        border: 1px solid var(--gray-500);
        width: 1.5rem;
        height: 1.5rem;
        background: #fff;
      }

      &::after {
        content: '';
        position: absolute;
        display: inline-block;
        left: -1.875rem;
        top: 0.375rem;
        width: 0.75rem;
        height: 0.75rem;
      }

      &[data-checked='true']::after {
        background: var(--dark);
      }
    }
  }
}
