.container {
  padding: 2px 4px;

  &[data-type='button-chip'] {
    cursor: pointer;
    background: transparent;

    border: 1px solid var(--gray-500);
    border-radius: 25px;
    padding: 0.5rem 1rem;

    font-weight: bold;
    line-height: 1;
  }
}
