.header {
  &[data-show-navbar='false'] {
    overflow: hidden;
    height: var(--main-navbar-height, '0px');

    animation: hide-row 0.9s ease-in forwards;

    @keyframes hide-row {
      90%,
      100% {
        height: 0;
        opacity: 0;
      }
      100% {
        display: none;
      }
    }
  }
}

.title {
  transition: all 1.5s var(--timing-function) 0s;

  & a {
    transition: inherit;
    color: var(--gray-700);
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  @media (max-width: 840px) {
    & {
      display: none;
    }
  }
}
