// Padding and margin
$counter: 32 * 2;
$step: 0.25;

$box-positions: (
  t: top,
  r: right,
  l: left,
  b: bottom,
);

$box-spaces: (
  p: padding,
  m: margin,
);

@for $i from 0 through $counter {
  $value: #{$i * 0.125}rem;
  $unit: escape-dot($i / 2);

  @each $rule, $attribute in $box-spaces {
    //=====================================
    // physical properties
    //=====================================
    // ex .m-1, .p-1, etc...
    .#{$rule}-#{$unit} {
      #{$attribute}: $value;
    }
    // ex .mx-1, .px-1, etc...
    .#{$rule}x-#{$unit} {
      #{$attribute}-left: $value;
      #{$attribute}-right: $value;
    }
    // ex .my-1, .py-1, etc...
    .#{$rule}y-#{$unit} {
      #{$attribute}-top: $value;
      #{$attribute}-bottom: $value;
    }
    // ex .mt-1, .pt-1, etc...
    @each $pos-rule, $pos-attribute in $box-positions {
      .#{$rule}#{$pos-rule}-#{$unit} {
        #{$attribute}-#{$pos-attribute}: $value;
      }
    }
    //=====================================
    // logical properties
    //=====================================
    // ex .p-inline-1, .m-inline-1, etc...
    .#{$rule}-inline-#{$unit} {
      #{$attribute}-inline: $value;
    }
    // ex .p-block-1, .m-block-1, etc...
    .#{$rule}-block-#{$unit} {
      #{$attribute}-block: $value;
    }
    // ex .ps-1, .ms-1, etc...
    .#{$rule}s-#{$unit} {
      #{$attribute}-inline-start: $value;
    }
    // ex .pe-1, .me-1, etc...
    .#{$rule}e-#{$unit} {
      #{$attribute}-inline-end: $value;
    }
  }
}

//  margin auto
.m-auto {
  margin: auto;
}

.m-inline-auto {
  margin-inline: auto;
}

.m-block-auto {
  margin-block: auto;
}

@each $rule, $attribute in $box-positions {
  .m#{$rule}-auto {
    margin-#{$attribute}: auto;
  }
}

// negative margin and padding
$count: 8;
$step: 0.25;

@for $i from 0 through $count {
  .-mt-#{$i} {
    margin-top: -#{$i * $step}rem;
  }

  .-mr-#{$i} {
    margin-right: -#{$i * $step}rem;
  }

  .-ml-#{$i} {
    margin-left: -#{$i * $step}rem;
  }

  .-mb-#{$i} {
    margin-bottom: -#{$i * $step}rem;
  }

  .-mx-#{$i} {
    margin-left: -#{$i * $step}rem;
    margin-right: -#{$i * $step}rem;
  }

  .-my-#{$i} {
    margin-top: -#{$i * $step}rem;
    margin-bottom: -#{$i * $step}rem;
  }
}
