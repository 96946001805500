.container {
  user-select: none;

  display: grid;
  place-content: center;
  grid-auto-flow: column;
  gap: 0.5rem;

  background-color: transparent;
  border: 1px solid var(--gray-500);
  border-radius: 400rem;

  font-weight: 700;
  color: #191919;
  line-height: 1rem;
  white-space: nowrap;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;

  &:where(:not([data-override-style])) {
    font-size: 1rem;
    height: fit-content;
    padding: 1rem 2rem;
  }

  transition: all 30ms ease-in-out;

  &:hover,
  & > *:hover {
    transform: scale(1.05);
  }

  &:disabled {
    transform: none;
    cursor: not-allowed;
    opacity: 0.5;
    color: var(--disabled-color);
  }

  &:active {
    transform: scale(1);
  }

  &[data-type='primary'] {
    color: var(--on-primary);
    background-color: var(--primary);
    border-color: var(--primary);
  }

  &[data-type='secondary'] {
    color: var(--on-secondary);
    background-color: var(--secondary);
    border-color: var(--gray-500);
  }

  &[data-type='warning'] {
    color: var(--on-warning);
    background-color: var(--warning);
    border-color: var(--warning);
  }

  &[data-full-width='true'] {
    width: 100%;
  }

  &[data-size='sm'] {
    font-size: 0.75rem;
    padding: 0.45rem 0.75rem;
  }

  &[data-size='md'] {
    font-size: 0.875rem;
    padding: 0.5rem 1.5rem;
  }

  &[data-size='lg'] {
    padding: 0.5rem 1.5rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 400rem;
  }

  &[data-size='compact'] {
    height: 2rem;
    font-size: 0.875rem;
    padding: 1rem;
  }

  &[data-animate-press='true'] {
    animation: pressButton 0.5s;
  }

  @keyframes pressButton {
    from {
      transform: scale(1.05);
    }
    to {
      transform: scale(1);
    }
  }
}
