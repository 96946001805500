.container {
  width: fit-content;
  display: flex;

  padding: 0.2rem 0.5rem;
  background-color: var(--gray-300);
  background-clip: padding-box;
  border-radius: 4px;

  font-size: 0.875rem;
  font-weight: bold;
  line-height: 1;
  letter-spacing: -0.22px;
  color: var(--gray-600);

  span {
    margin-inline-start: 0.5rem;
    display: inline-block;
  }
}
