.container {
  display: grid;

  &[data-view-template-type='cols'] {
    grid-template-areas: 'chart-legend' 'chart-area' 'chart-totals';
  }

  :global {
    .chart-legend {
      grid-area: chart-legend;

      .legend-list {
        .legend-item {
          font-size: 1rem;
          letter-spacing: 0px;
          color: #191919;
          text-shadow: 0px 3px 6px #00000029;
          display: grid;
          grid-auto-flow: column;
          align-items: baseline;
          justify-content: flex-start;
          gap: 1rem;

          &:first-child {
            margin-bottom: 1rem;
            --bullet-color: var(--current-major-bg-color);
          }

          &:last-child {
            --bullet-color: var(--target-major-bg-color);
          }

          &::before {
            content: '';
            width: 1rem;
            height: 1rem;
            background: var(--bullet-color) 0% 0% no-repeat padding-box;
            border-radius: 12px;
            position: relative;
            top: 2px;
          }
        }
      }
    }

    .chart-area {
      grid-area: chart-area;
      margin-bottom: 3rem;
    }

    .chart-totals {
      grid-area: chart-totals;
      .item-title {
        margin-right: 0.5rem;
        margin-bottom: 0;
      }
    }
  }
}
