/// Font size
/// Utilities for controlling the font size of an element.
/// @group Typography
.text-xs {
  font-size: 0.75rem; /* 12px */
  line-height: 1rem; /* 16px */
}
/// @group Typography
.text-sm {
  font-size: 0.875rem; /* 14px */
  line-height: 1.25rem; /* 20px */
}
/// @group Typography
.text-base {
  font-size: 1rem; /* 16px */
  line-height: 1.5rem; /* 24px */
}
/// @group Typography
.text-lg {
  font-size: 1.125rem; /* 18px */
  line-height: 1.75rem; /* 28px */
}
/// @group Typography
.text-xl {
  font-size: 1.25rem; /* 20px */
  line-height: 1.75rem; /* 28px */
}
/// @group Typography
.text-2xl {
  font-size: 1.5rem; /* 24px */
  line-height: 2rem; /* 32px */
}
/// @group Typography
.text-3xl {
  font-size: 1.875rem; /* 30px */
  line-height: 2.25rem; /* 36px */
}
/// @group Typography
.text-4xl {
  font-size: 2.25rem; /* 36px */
  line-height: 2.5rem; /* 40px */
}
/// @group Typography
.text-5xl {
  font-size: 3rem; /* 48px */
  line-height: 1;
}
/// @group Typography
.text-6xl {
  font-size: 3.75rem; /* 60px */
  line-height: 1;
}
/// @group Typography
.text-7xl {
  font-size: 4.5rem; /* 72px */
  line-height: 1;
}
/// @group Typography
.text-8xl {
  font-size: 6rem; /* 96px */
  line-height: 1;
}
/// @group Typography
.text-9xl {
  font-size: 8rem; /* 128px */
  line-height: 1;
}

/// Font-size
$count: 16;
$step: 0.25;

@for $i from 0 through $count {
  /// @group Typography
  .text-#{$i} {
    font-size: #{$i * $step}rem;
  }
}

/// Font Weight
/// Utilities for controlling the font weight of an element.
$font-weights: (
  thin: 100,
  extralight: 200,
  light: 300,
  normal: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
  extrabold: 800,
  black: 900,
);

@each $rule, $value in $font-weights {
  /// @group Typography
  .font-#{$rule} {
    font-weight: #{$value};
  }
}
/// emulate a font bold. Useful nav link or those cases where the change
/// of font-weight may cause unwanted reflow
.text-shadow-bold {
  text-shadow: 1px 0px 0px currentColor;
}
/// Letter Spacing
/// Utilities for controlling the tracking (letter spacing) of an element.
/// @group Typography

$letter-spacings: (
  tighter: -0.05em,
  tight: -0.025em,
  normal: 0em,
  wide: 0.025em,
  wider: 0.05em,
  widest: 0.1em,
);

@each $rule, $value in $letter-spacings {
  /// @group Typography
  .tracking-#{$rule} {
    letter-spacing: #{$value};
  }
}

/// Line Clamp
/// Utilities for clamping text to a specific number of lines.
@for $value from 1 through 6 {
  /// @group Typography
  .line-clamp-#{$value} {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: #{$value};
    line-clamp: #{$value};
  }
}
/// @group Typography
.line-clamp-none {
  overflow: visible;
  display: block;
  -webkit-box-orient: horizontal;
  -webkit-line-clamp: none;
}

/// Line Height
/// Utilities for controlling the leading (line height) of an element.
/// @group Typography
.leading-sm {
  line-height: 0.75;
}
.leading-none {
  line-height: 1;
}
.leading-tight {
  line-height: 1.25;
}
.leading-snug {
  line-height: 1.375;
}
.leading-normal {
  line-height: 1.5;
}
.leading-relaxed {
  line-height: 1.625;
}
.leading-loose {
  line-height: 2;
}

$count: 10;
$step: 0.25;

@for $i from 3 through $count {
  /// @group Typography
  .leading-#{$i} {
    line-height: #{$i * $step}rem;
  }
}

/// List Style Position
/// Utilities for controlling the position of bullets/numbers in lists.
.list-inside {
  list-style-position: inside;
}
.list-outside {
  list-style-position: outside;
}

/// List Style Type
/// Utilities for controlling the bullet/number style of a list.
.list-none {
  list-style-type: none;
}
.list-disc {
  list-style-type: disc;
}
.list-decimal {
  list-style-type: decimal;
}

/// Text Align
/// Utilities for controlling the alignment of text.
/// @group Typography
$alignment-list: (right, left, center);
@each $alignment in $alignment-list {
  /// @group Typography
  .text-#{$alignment} {
    text-align: #{$alignment};
  }
}

/// Text Color
/// Utilities for controlling the text color of an element.
/// @group Typography
@for $index from 1 through 8 {
  $value: $index * 100;

  /// @group Typography
  .text-gray-#{$value} {
    color: var(--gray-#{$value});
  }
  /// @group Typography
  .hover\:text-gray-#{$value}:hover {
    color: var(--gray-#{$value});
  }
}
/// @group Typography
.text-gray-650 {
  color: var(--gray-650);
}

@for $index from 1 through 3 {
  $i: $index * 100;
  .text-warning-#{$i} {
    color: var(--warning-#{$i});
  }
}

@for $index from 3 through 9 {
  $i: $index * 100;
  .text-orange-#{$i} {
    color: var(--orange-#{$i});
  }
}

@for $index from 3 through 9 {
  $i: $index * 100;
  .text-blue-#{$i} {
    color: var(--blue-#{$i});
  }
}

.text-error {
  color: var(--error-dark);
}

.text-error-light {
  color: var(--error-light);
}

.text-info {
  color: var(--blue-600);
}

.text-success {
  color: var(--green-600);
}

.text-inherit {
  color: inherit;
}

.text-current {
  color: currentColor;
}

.text-transparent {
  color: transparent;
}

$text-colors: ('black', 'white', 'maroon', 'gold');

@each $value in $text-colors {
  .hover\:text-#{$value}:hover,
  .text-#{$value} {
    color: var(--#{$value});
  }
}

.text-primary {
  color: var(--primary);
}

/// Text Decoration
/// Utilities for controlling the decoration of text.
/// @group Typography
.no-underline {
  text-decoration: none;
}
.underline {
  text-decoration-line: underline;
}
.hover\:underline:hover {
  text-decoration-line: underline;
  cursor: pointer;
}

.hover\:underline-children:hover > *:not(*.no-underline) {
  text-decoration-line: underline;
  cursor: pointer;
}

/// Typography
///
/// Text Decoration Color
/// Utilities for controlling the color of text decorations.
.decoration-inherit {
  text-decoration-color: inherit;
}

.decoration-current {
  text-decoration-color: currentColor;
}

.decoration-red {
  text-decoration-color: var(--red);
}
/// Text Transform
/// Utilities for controlling the transformation of text.
$text-transform: (uppercase, lowercase, capitalize);

@each $value in $text-transform {
  /// @group Typography
  .#{$value} {
    text-transform: #{$value};
  }
}
/// @group Typography
.normal-case {
  text-transform: none;
}

/// Text wrap
/// Utilities for controlling the text wrap.
$text-wrap: (wrap, nowrap, balance, pretty, stable);

@each $value in $text-wrap {
  /// @group Typography
  .text-#{$value} {
    text-wrap: #{$value};
  }
}
