.container {
  position: relative;
  height: calc(100vh / 4 * 2.7);
  display: grid;
  align-items: center;
  justify-content: center;

  & * {
    text-align: center;
    line-height: 1.1;
  }

  h2 {
    font-size: 4rem;
  }
  h3 {
    font-size: 2.5rem;
  }

  &:before {
    background: url(../../../img/asu-logo/asu-sunburst-maroon-gold.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    object-fit: cover;
    /* filter: grayscale(); */
    opacity: 0.06;

    width: 100%;
    height: 80%;

    content: '';
    position: absolute;
    z-index: -1;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    top: 50%;
    transform: translateY(-50%);
  }

  :global {
    & .content {
      &:before {
        content: '';
        position: absolute;
        inset: 0;
        padding: 2rem;
        background-color: #fff;
        border-radius: 15px;
        filter: blur(5px);
        opacity: 0.43;
        z-index: -1;
      }

      padding: 2rem;
      position: relative;
    }
  }
}
