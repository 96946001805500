.container {
  cursor: pointer;

  border: none;
  background: none;
  padding: 0;

  font-size: inherit;
  font-family: inherit;
  height: fit-content;

  &:disabled {
    cursor: not-allowed;
    color: var(--disabled-color);
    transform: none;
  }
}
