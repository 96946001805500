svg[data-type='stacked-bar-chart'] {
  overflow: visible;
  font-size: 1rem;
}

svg[data-type='stacked-bar-chart'] text {
  font-size: 0.75rem;
}

svg[data-type='stacked-bar-chart'] text.top-bar-text {
  fill: #000;
}

svg[data-type='stacked-bar-chart'] .y-axis-0 text {
  fill: var(--area-text-color-1)
}

svg[data-type='stacked-bar-chart'] .y-axis-1 text {
  fill: var(--area-text-color-2)
}

svg[data-type='stacked-bar-chart'] .bar:hover {
  filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.5));
  transition: filter 0.3s ease-in;
}
