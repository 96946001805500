.container {
  li {
    display: flex;
  }
}

#list-page-number {
  & li button:not(:disabled):not([data-current='true']):hover {
    background-color: rgba(24, 18, 18, 0.08);
    transition: background-color 0.3s ease-out;
  }
}
