.container {
  --wk-width: calc(var(--chip-visible-text-width, auto) + 1rem);
  width: var(--wk-width);
  background-color: var(--bg-color);

  .content {
    transform-origin: right center;
    transform: translateX(0px);
    transition:
      transform ease-out 0.3s,
      background-color ease-out 0.3s;
  }

  .chip-hidden-content {
    text-wrap: nowrap;
    opacity: 0;
    transition: opacity ease-out;
    transition-duration: 0.1s;
  }

  &[data-hover] {
    .content {
      background-color: var(--bg-active-color);
      transform: translateX(calc(-100% + var(--wk-width)));
    }

    & .chip-hidden-content {
      opacity: 1;
      transition-duration: 0.5s;
    }
  }
}
