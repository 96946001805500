svg[data-type='pie-chart'] {
  overflow: visible;
  font-size: 1rem;
  color: #191919;
}

.circle-range:hover {
  filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.5));
  transition: filter 0.3s ease-in;
}

.pie-chart-label {
  z-index: 1000;
  width: 75%;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  line-height: normal;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.pie-chart-label strong {
  font-size: 2rem;
  font-weight: bold;
}

.pie-chart-label span {
  font-size: 1rem;
  font-weight: bold;
}
