.container {
  .body {
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: 0fr;
    gap: 0.25rem;
    opacity: 0;
    transition:
      grid-template-rows ease 0.6s,
      opacity ease 0.6s;

    & > * {
      overflow: hidden;
    }

    animation: show 0.4s ease-out forwards;

    @keyframes show {
      to {
        grid-template-rows: 1fr;
        opacity: 1;
        padding-block: 0.75rem;
      }
    }
  }
}
