.container {
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  margin-right: auto;
  margin-left: auto;
}

@include media-breakpoint-up(sm) {
  .container {
    max-width: 540px;
  }
}

@include media-breakpoint-up(md) {
  .container {
    max-width: 768px;
  }
}

@include media-breakpoint-up(lg) {
  .container {
    max-width: 992px;
  }
}

@include media-breakpoint-up(xl) {
  .container {
    max-width: 1224px;
  }
}
