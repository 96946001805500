.container {
  display: flex;
  align-items: center;
  justify-content: center;

  background: rgba(0, 0, 0, 0.6);

  position: fixed;
  z-index: 1000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  width: 100%;
  height: 100vh;

  :global {
    .loader {
      background-color: #ffffff;
      box-shadow: 0px 3px 6px #00000029;
      opacity: 1;

      padding: 3rem;
      max-height: 500px;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .loader-message {
      text-align: center;
      letter-spacing: -1.15px;
      color: #191919;
      line-height: 3rem;
      font-weight: bold;
      width: 621px;
      height: 99px;

      margin: 0;

      display: flex;
      flex-direction: column;
    }

    .loader-message .message-title {
      font-size: 3rem;
    }

    .loader-message .message-description {
      font-size: 2rem;
    }

    .loader-animator {
      --height: 330px;
      height: var(--height);
      width: 403px;

      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
    }

    .dot {
      --dot-size: 40px;
      --y-offset: calc(var(--height) / 3 - var(--dot-size) / 2);
      --duration: 3s;
      --delay: 0;

      background: #000000 0% 0% no-repeat padding-box;

      width: var(--dot-size);
      height: var(--dot-size);

      will-change: transform;

      border-radius: 100%;
      transition: transform;

      & :local {
        animation: bounce var(--duration) ease-in-out infinite alternate;
      }

      @keyframes bounce {
        50% {
          transform: translateY(calc(-1 * var(--y-offset, 1)));
        }

        100% {
          transform: translateY(var(--y-offset, 1));
        }
      }
    }

    .dot-1 {
      animation-delay: 0.33s;
    }

    .dot-2 {
      animation-delay: 0;
    }

    .dot-3 {
      animation-delay: 0.66s;
    }
  }
}
