.container {
  position: relative;
  --anchor-outline: 2px;
  --anchor-outline-offset: 3px;
  --anchor-size-offset: calc(
    var(--anchor-outline) + var(--anchor-outline-offset)
  );

  .anchor-element {
    & > [aria-expanded='true'] {
      outline: var(--anchor-outline) solid var(--focus-color);
      outline-offset: var(--anchor-outline-offset);
      box-shadow: none;
    }
  }
}

.list {
  display: none;
  position: absolute;
  z-index: 200;
  list-style: none;
  background-color: #ffffff;
  box-shadow: 0px 3px 6px #00000029;

  font-size: 1rem;
  width: max-content;
  min-width: 150px;

  &[data-sub-menu='true'] {
    z-index: 100;
  }

  &[data-items-count='1'] {
    min-width: max-content;
  }

  &[data-direction='right'] {
    transform-origin: top left;
  }

  &[data-direction='left'] {
    transform-origin: top right;
  }

  &[data-animate-hidden='true'] {
    display: grid;
    pointer-events: none;

    &[data-sub-menu='true'] {
      --hide-speed: 0.1s;
      --hide-delay: 0s;
    }

    animation: hide-menu var(--hide-speed, 0.7s) ease-in forwards;
    animation-delay: var(--hide-delay, 0.7s);
    @keyframes hide-menu {
      from {
        opacity: 1;
      }
      to {
        opacity: 0;
        display: none;
      }
    }
  }

  &[data-is-open='true'] {
    display: grid;
  }

  &[data-animate-show='true'] {
    opacity: 1;

    &[data-sub-menu='true'] {
      --hide-speed: 0.1s;
    }

    animation: show-menu var(--hide-speed, 0.25s) ease-out forwards;
    @keyframes show-menu {
      from {
        scale: 0;
      }
      to {
        scale: 1;
      }
    }
  }

  li {
    padding-inline: 1rem;
    cursor: pointer;
    letter-spacing: 0px;
    color: var(--gray-700);

    &[data-disabled='true'] {
      cursor: not-allowed;
      opacity: 0.3;
      &:hover {
        text-decoration: none;
      }
    }

    a {
      width: 100%;
      color: inherit;
      &:hover {
        color: #191919;
        text-decoration: underline;
      }
    }
  }
}
