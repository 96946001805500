.bg-inherit {
  background-color: inherit;
}

.bg-current {
  background-color: currentColor;
}

.bg-transparent {
  background-color: transparent;
}

.bg-black {
  background-color: #000000;
}

.bg-white {
  background-color: #ffffff;
}

.bg-dark {
  background-color: var(--dark);
}

.bg-maroon {
  background-color: var(--maroon);
}

.bg-gold {
  background-color: var(--gold);
}

.bg-green {
  background-color: var(--green);
}

.bg-blue {
  background-color: var(--blue);
}

.bg-info {
  background-color: var(--info);
}

.bg-error {
  background-color: var(--error-dark);
}

.bg-error-light {
  background-color: var(--error-light);
}

@for $i from 1 through 3 {
  $value: $i * 100;
  .bg-warning-#{$value} {
    background-color: var(--warning-#{$value});
  }
}

.bg-primary {
  background-color: var(--primary);
}

/// Background Color
/// orange
@for $index from 1 through 9 {
  $i: $index * 100;
  .bg-orange-#{$i} {
    background-color: var(--orange-#{$i});
  }
}

/// gray
@for $i from 1 through 7 {
  $value: $i * 100;
  .bg-gray-#{$value} {
    background-color: var(--gray-#{$value});
  }

  .bg-gray-#{$i} {
    background-color: var(--gray-#{$i});
  }

  .hover\:bg-gray-#{$value}:hover {
    background-color: var(--gray-#{$value});
  }
}

@for $value from 110 through 170 {
  .bg-gray-#{$value} {
    background-color: var(--gray-#{$value});
  }
}

.highlight-gold {
  box-shadow:
    -0.15em 0 0 #ffc627,
    0.15em 0 0 #ffc627;
  background: #ffc627;
  color: #191919;
}

.highlight-black {
  background: #191919;
  box-shadow:
    -0.15em 0 0 #191919,
    0.15em 0 0 #191919;
  color: #fafafa;
}

//#region  custom background
body.topography-pattern {
  background: url(../img/topo-pattern-white.png);
}

body.topography-pattern .topography-container {
  background-color: #ffffff;
}

.bg-topo-white {
  position: relative;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-inline: 1px solid #00000029;
  width: fit-content;
  margin: 0 auto;
}

.bg-topo-white:before {
  content: '';
  background: url(../img/topo-pattern-white.png);
  margin-inline: -50vw;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  right: 50%;
  isolation: isolate;
  z-index: -1;
}

@media only screen and (max-width: 1260px) {
  .bg-topo-white:before {
    margin-inline: -50vw;
    left: 50%;
    right: 50%;
  }
}

//#endregion custom background
