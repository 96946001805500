.container {
  --link-hover-width: 100%;
  --link-hover-left: 0;

  &::before,
  &::after {
    content: '';
    position: absolute;
    border-bottom: 4px solid;
    height: 0;
    bottom: -1px;
    opacity: 0;
    transition-timing-function: var(--timing-function);
    transition-duration: 0.2s;
  }

  &::before {
    width: var(--link-hover-width);
    left: var(--link-hover-left);
    border-bottom-color: var(--gray-400);
    transition-property: opacity;
  }

  &::after {
    width: var(--link-active-width);
    left: 0;
    transform: translateX(var(--link-active-left));
    border-bottom-color: var(--link-active-color);
    transition-property: transform;
  }

  &[data-link-hover='true']::before,
  &[data-link-active='true']::after {
    transition-duration: 0.5s;
    opacity: 1;
  }
}
