.list-items {
  list-style: none;
  padding: 0;
}

.list-none {
  list-style-type: none;
}

.list-disc {
  list-style-type: disc;
}

.list-decimal {
  list-style-type: decimal;
}

.list-inside {
  list-style-position: inside;
}

.list-outside {
  list-style-position: outside;
}
