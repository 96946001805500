.container {
  cursor: pointer;

  &[aria-disabled='true'] {
    cursor: not-allowed;
  }

  transition: background-color 0.5s ease-out;

  &:hover,
  &[aria-checked='true'] {
    background-color: var(--gray-200);
    color: var(--gray-600);
  }
}
