.container {
  min-height: 30px;
  height: fit-content;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  background-color: #e8e8e8;
  transition: all 0.5s cubic-bezier(0.19, 1, 0.19, 1) 0s;

  :global {
    .links-container {
      display: flex;

      .nav-link {
        color: #484848;
        font-size: 0.75rem;
        line-height: 0.75rem;
        padding: 0.25rem 0.5rem;
        margin-right: 0.5rem;
        white-space: nowrap;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }

        &.sr-only:not(:focus):not(:active) {
          position: absolute;
          padding: 0;
          width: 1px;
          height: 1px;
          overflow: hidden;
          clip: rect(0, 0, 0, 0);
          white-space: nowrap;
          border-width: 0;
        }

        &.sr-only-focusable:active,
        &.sr-only-focusable:focus {
          clip: auto;
          position: static;
          white-space: normal;

          overflow: visible;
          width: auto;
          height: auto;
          margin: 0;
        }
      }
    }

    @media (max-width: 840px) {
      #top-bar-user-name {
        display: none;
      }
    }
  }
}

.form-search {
  isolation: isolate;

  justify-content: center;
  align-items: center;

  :global {
    [data-search-open='true'] & .search-button {
      pointer-events: none;
      z-index: 1;
    }

    [data-search-open='false'] & {
      .search-input,
      .search-close {
        display: none;
      }
    }

    .search-button {
      border: none;
      background: transparent;
      cursor: pointer;
      line-height: 1;
      margin: 0.3rem;
    }

    .search-input {
      width: 200px;
      background-color: rgb(255, 255, 255);
      border-radius: 0px;
      padding: 0.5rem 0.5rem 0.5rem 2rem;
      font-weight: 400;
      line-height: 1.5;
      color: rgb(73, 80, 87);
      border: 1px solid rgb(112, 112, 112);
      margin: 0.5rem 0px;
      display: unset;
      margin-left: -2rem;
    }

    .search-close {
      background-color: rgb(255, 255, 255);
      border: 1px solid rgb(208, 208, 208);
      border-radius: 100%;
      padding: 0.45rem 0.75rem;
      margin-inline: 0.45rem;
      height: fit-content;
    }
  }
}
