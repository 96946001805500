.container {
  height: fit-content;

  &[tabindex] {
    cursor: pointer;
  }

  &[data-on-hover-show-box-shadow='true'] {
    border-radius: 50%;
    box-sizing: border-box;
    transition: box-shadow 0.2s ease;
    &:hover {
      box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.3);
    }
  }

  .range-label {
    fill-opacity: 0;
    transition: fill-opacity 0.7s ease;
  }
  &[data-on-hover-show-data-label='true'] {
    &:hover .range-label {
      fill-opacity: 1;
    }
  }

  &[data-animate='true'] {
    .path {
      animation: display-path var(--animation-duration, 0.7s)
        var(--animation-delay, 0) linear forwards;
    }
    @keyframes display-path {
      to {
        stroke-dashoffset: 0;
        opacity: 1;
      }
    }

    text,
    foreignObject {
      opacity: 0;
      animation: display-text 1.1s linear forwards;
      @keyframes display-text {
        to {
          opacity: 1;
        }
      }
    }
  }

  .path {
    opacity: 0;
    stroke-dasharray: var(--path-length);
    stroke-dashoffset: var(--path-length);

    --path-color: white;
    filter: drop-shadow(1px 0 0 var(--path-color))
      drop-shadow(-1px 0 0 var(--path-color))
      drop-shadow(0 -1px 0 var(--path-color))
      drop-shadow(0 1px 0 var(--path-color));
  }
}
