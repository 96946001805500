.header {
  display: grid;
  place-content: center;
  align-self: start;
  gap: 2rem;

  .ico-container {
    margin: auto;
    background-color: var(--gray-200);
    border-radius: 50%;

    display: grid;
    place-content: center;

    width: 140px;
    height: 140px;

    svg {
      height: 56px;
    }
  }

  h2 {
    font-weight: bold;
    font-size: 2rem;
    line-height: 2.375rem;
    letter-spacing: -1.02px;
    color: var(--gray-700);
  }
}

.body {
  margin: auto;
  font-size: 1.5rem;
  line-height: 1.875rem;
  letter-spacing: 0px;
  color: var(--gray-600);
}

.footer {
  display: flex;
  justify-content: space-between;
  align-self: end;
}
