.switch {
  --bg-color: #ccc;
  --bg-active-color: green;
  --switch-width: 60px;
  --switch-padding: 4px;
  --slider-width: 0px;

  width: var(--switch-width);
  position: relative;
  display: inline-block;

  &.round {
    border-radius: 34px;
  }

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .slider {
      background-color: var(--bg-active-color);
    }

    &:focus + .slider {
      box-shadow: 0 0 1px var(--bg-active-color);
    }

    &:checked + .slider:before {
      --right-offset: calc(var(--switch-padding) * 2) - var(--slider-width,);
      --slider-pos: calc(var(--switch-width) - var(--right-offset));
      transform: translateX(var(--slider-pos));
    }
  }

  .slider {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--bg-color);
    transition: 0.4s;
    border-radius: inherit;

    &:before {
      border-radius: inherit;
    }

    &:before {
      position: absolute;
      content: '';
      aspect-ratio: 1;
      height: 70%;
      top: var(--switch-padding);
      left: var(--switch-padding);
      background-color: white;
      transition: 0.4s;
    }
  }
}
