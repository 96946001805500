.container {
  height: 1rem;
  width: fit-content;
  transition: width ease-in-out 2.3s;

  background-color: var(--bg-color);
  border-radius: 0.625rem;
  overflow: hidden;

  .chip-hidden-content {
    display: inline-block;
    text-wrap: nowrap;

    opacity: 0;
    width: var(--chip-width-start);
    transition:
      width ease-in 0.25s,
      opacity ease-out 0.3s;

    &[data-hidden='true'] {
      visibility: hidden;
      position: fixed;
    }
  }

  &:hover {
    background-color: var(--bg-active-color);
    transition: background-color ease-out 0.3s;

    & .chip-hidden-content {
      opacity: 1;
      width: var(--chip-width-end);
      transition:
        opacity ease-out 0.9s,
        width ease-out 0.5s;
    }
  }
}
