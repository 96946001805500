/**
 *  This style aims to make the scrollbar experience consistent across
 *  the major browsers. It target 2 main engines,  Firefox and chromium.
 *
 * reference:
 *   [Firefox]
 *   - https://developer.mozilla.org/en-US/docs/Web/CSS/scrollbar-width
 *   - https://developer.mozilla.org/en-US/docs/Web/CSS/scrollbar-color
 *   [chromium]
 *   - https://developer.mozilla.org/en-US/docs/Web/CSS/::-webkit-scrollbar
 *   - https://developer.mozilla.org/en-US/docs/Web/CSS/scrollbar-width
 *   - https://developer.mozilla.org/en-US/docs/Web/CSS/scrollbar-color
 *   - https://developer.mozilla.org/en-US/docs/Web/CSS/scrollbar-gutter
 */

/**
 - firefox & chromium:  `scrollbar-*`
 - any other browser have some support for `::-webkit-*` selectors
 - attributes `scrollbar-*` make irrelevant `::-webkit-*` selectors
*/

body[data-scrollbar-always-visible='true'] {
  .hide-scrollbar {
    // firefox & chromium
    scrollbar-width: none;
    // chromium
    &::-webkit-scrollbar,
    &::-webkit-scrollbar-track,
    &::-webkit-scrollbar-thumb {
      width: 0 !important;
      background-color: transparent !important;
    }
  }

  .hide-children-scrollbar {
    // firefox
    scrollbar-width: none;
    // chromium
    & *::-webkit-scrollbar,
    & *::-webkit-scrollbar-track,
    & *::-webkit-scrollbar-thumb {
      width: 0;
    }
  }

  .scrollbar-base {
    --scrollbar-thumb-color: var(--gray-400);

    @supports #{'not selector(::-webkit-scrollbar)'} {
      // firefox && chromium
      scrollbar-width: thin;
      scrollbar-color: var(--scrollbar-thumb-color) transparent;
    }

    &:hover {
      --scrollbar-thumb-color: var(--gray-500);
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--scrollbar-thumb-color);
      border-radius: 0.7rem;
      width: 0.4rem;
    }

    &::-webkit-scrollbar-track {
      border-left: 1px solid var(--gray-200);
      width: 0.4rem;
    }
  }

  .scrollbar-none {
    scrollbar-width: none;
  }

  .scrollbar-stable {
    --scrollbar-width: 0.5rem;
    --scrollbar-offset: 0.5rem;

    --scrollbar-track-color: transparent;
    --scrollbar-thumb-color: var(--gray-400);

    padding-right: var(--scrollbar-offset) !important;

    @supports #{'not selector(::-webkit-scrollbar)'} {
      // firefox && chromium
      scrollbar-color: var(--scrollbar-thumb-color) var(--scrollbar-track-color);
      scrollbar-width: auto;
    }

    &:hover {
      --scrollbar-thumb-color: var(--gray-500);
    }

    &::-webkit-scrollbar {
      background-color: transparent;
      width: var(--scrollbar-width);
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      background-clip: content-box;
      background-color: var(--scrollbar-thumb-color);
    }

    &::-webkit-scrollbar-track {
      background-color: var(--scrollbar-track-color);
    }
  }
}
