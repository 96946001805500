/// Cursor
/// Utilities for controlling the cursor style when hovering over an element.
$cursor-types: (
  'auto',
  'default',
  'pointer',
  'wait',
  'text',
  'move',
  'help',
  'not-allowed',
  'non' e
);

@each $value in $cursor-types {
  .cursor-#{$value} {
    cursor: #{$value};
  }
}
