// Default margins are removed

// # Headings
blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}

// # Lists
ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

// # Images
img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block;
  vertical-align: middle;
}

// headings
h1,
h2,
h3,
h4,
h5 {
  font-weight: 700;
  line-height: calc(100% + 0.2em);
}

h2 {
  font-size: 2.5rem;
  letter-spacing: -0.0875rem;
}

h3 {
  font-size: 1.5rem;
  letter-spacing: -0.0525rem;
}

[type='button']:not(:disabled),
[type='reset']:not(:disabled),
[type='submit']:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}

*,
:after,
:before {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}
